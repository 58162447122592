import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {

  render() {

    return (
      <Layout>
        <Helmet title="श्री गुरूगजाननलीला ग्रंथ - नमस्कार" />
        <HeaderGeneric title="श्री गुरूगजाननलीला ग्रंथ" adhyaay='नमस्कार'/>
        <div id="main">
          <section id="content" className="main">
            <p>
नमस्कार माझा तया गजाननाला । < br />
जो धावून येई भक्तांच्या हाकेला ॥ धृ ॥< br />
< br />
ठिणगीवाचून चिलिमीस पेटवले । < br />
कोरड्या विहिरीत जल उत्पन्न केले । < br />
जानरावाचे गंडांतर तीर्थाने टाळले । < br />
आशिर्वादे खंडूस पुत्र रत्न झाले ॥ १ ॥< br />
< br />
मारुतीपंताच्या रक्षिले मळ्याला ।< br />
पितांबरावरील प्रसंग निवारला । < br />
दिल्या पादुका त्या पुंडलीकाला ।< br />
विठ्ठल दर्शन बापुना काळ्याला ॥ २ ॥< br />
< br />
बेडीमुक्त केले खंडुपाटिलाला ।< br />
ऋणमुक्त केले बंडुतात्याला ।< br />
सुरुंगातुन वाचवले गणू जवर्‍याला ।< br />
रक्षीले ज्याने बायजेच्या अब्रूला ॥ ३ ॥< br />
< br />
गंगाभारती झाला महारोग मुक्त ।< br />
वारकरी झाला कॉलरा मुक्त ।< br />
तुक्याच्या कानातुन छरा निघाला ।< br />
अंगार्‍याने भाऊस व्याधिमुक्त केला ॥ ४ ॥< br />
< br />
दिले संसारसुख हरी जाखड्याला ।< br />
योगविद्या शिकवली निमोणकराला ।< br />
बाळकृष्णास दिले रामदास दर्शन ।< br />
हरी पाटिलाला दिले विठ्ठल दर्शन ॥ ५ ॥< br />

</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
